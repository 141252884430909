"use client";

import { Apple } from "@/components/icons";
import { AnalyticsScreenName, analytics } from "@/lib/analytics";
import { logInWithApple } from "@/lib/auth";
import { useSearchParams } from "next/navigation";

export default function AppleLoginButton() {
  const searchParams = useSearchParams();

  return (
    <button
      type="button"
      className={`
        flex min-h-12 items-center justify-center
        gap-3 rounded-sm bg-yellow-100
        px-4
        py-3
        text-base
        font-semibold
        text-gray-900 duration-300
        hover:bg-yellow-400
      `}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        analytics.logEvent(AnalyticsScreenName.LogInWithApple, {});

        logInWithApple({
          redirectAfterLogIn: searchParams?.get("callbackUrl") || "",
        });
      }}
    >
      <Apple
        widthClass="w-5"
        heightClass="h-5 mb-0.5"
        colorClass="text-gray-900"
      />

      <div>Apple</div>
    </button>
  );
}
