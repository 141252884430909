"use client";

import LoginForm from "@/components/migration/auth/LoginForm";
import React from "react";
import { useSession } from "next-auth/react";
import { redirect } from "next/navigation";
export default function LoginPage() {
  const { status, data: session } = useSession();
  const isLoading = status === "loading";
  if (session?.user) {
    redirect("/home");
  }

  const homePageVideo = "/videos/homepage_video.mp4";
  return (
    <main className="flex h-screen w-full flex-col items-center justify-center p-4">
      <video
        autoPlay
        loop
        muted
        className={`
      fixed inset-0
      -z-[1] h-full w-full
      object-cover
    `}
      >
        <source src={homePageVideo} type="video/mp4" />
      </video>

      <div className="flex min-w-80 flex-col items-center justify-center sm:min-w-96">
        {isLoading ? (
          <div
            className={`
          absolute
          inset-0
          flex items-center justify-center
          bg-gray-900
          p-6
          text-white
        `}
          >
            <div>Loading...</div>
          </div>
        ) : (
          <LoginForm />
        )}
      </div>
    </main>
  );
}
