import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { AUTH_URL, STAGE, WEBAPP_URL } from "@/lib/env";
import { z } from "zod";
import { Button } from "@/components/buttons";
import { InputField } from "@/components/form";
import { useEffect } from "react";
import { useSearchParams } from "next/navigation";

export default function EmailInputForm() {
  const searchParams = useSearchParams();
  const prefillEmail = searchParams?.get("prefillEmail");
  const callbackUrl = searchParams?.get("callbackUrl");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: zodResolver(
      z.object({
        email: z.string().email(),
      }),
    ),
    defaultValues: {
      email: prefillEmail || "",
    },
  });

  useEffect(() => {
    if (prefillEmail) {
      setValue("email", prefillEmail);
    }
  }, [prefillEmail, setValue]);

  const onSubmit: SubmitHandler<{ email: string }> = (data) => {
    const emailAuthParams = new URLSearchParams({
      client_id: `webapp-${STAGE}`,
      provider: "email",
      email: data.email,
      response_type: "code",
      redirect_uri: `${WEBAPP_URL}/api/auth/sst/callback/`,
    });

    if (callbackUrl) {
      emailAuthParams.set("state", callbackUrl);
    }

    window.location.href =
      AUTH_URL + "/authorize?" + emailAuthParams.toString();
  };

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-1">
        <span className="text-xs text-red-500">{errors.email?.message}</span>

        <InputField
          required
          id="email"
          label="email"
          placeholder="Enter your email address"
          register={register}
          className={`${errors.email?.message ? "border border-red-500" : ""}`}
        />
      </div>

      <Button type="submit">Continue with Email</Button>
    </form>
  );
}
