"use client";

import { useEffect } from "react";
import Link from "next/link";
import { AnalyticsScreenName, analytics } from "@/lib/analytics";

import Logo from "@/components/images/Logo";
import GoogleLoginButton from "./GoogleLoginButton";
import AppleLoginButton from "./AppleLoginButton";
import EmailInputForm from "./EmailInputForm";

export default function LoginForm() {
  useEffect(() => {
    analytics.logEvent(AnalyticsScreenName.LogIn, {});
  }, []);

  return (
    <div
      className={`
      flex
      w-full flex-col gap-8
      rounded-sm
      bg-gray-800
      p-8 pt-6
    `}
    >
      <Logo
        bgColor="#ffd500"
        colorClass="text-black"
        heightClass="h-10"
        widthClass=""
      />

      <div className="flex flex-col items-center gap-8">
        <div className="flex w-full flex-col items-center gap-1">
          <div className="text-center text-2xl font-semibold text-gray-100">
            Log In to Setmixer
          </div>
          <div className="text-center text-base text-gray-400">
            Your home for live recordings
          </div>
        </div>

        <div className="flex w-full flex-col gap-8">
          <EmailInputForm />
          <div
            className={`
          flex h-px w-full
          items-center justify-center bg-gray-700
        `}
          >
            <div className="text-2xs bg-gray-800 px-4 font-semibold uppercase tracking-widest text-gray-400">
              or continue with
            </div>
          </div>
          <div className="grid grid-flow-col items-center justify-stretch gap-4">
            <GoogleLoginButton />
            <AppleLoginButton />
          </div>
          <div className="text-center text-xs text-gray-400">
            By logging in you accept{" "}
            <Link
              href="https://www.setmixer.com/terms"
              className="cursor-pointer text-gray-100 underline"
              target="_blank"
            >
              our T&Cs
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
